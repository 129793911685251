// this should be replaced with the i18n $t function in the future
let translate = (placeholder) => placeholder;

export function setLocaleFunction(func) {
    translate = func;
}

export function _locale(placeholder, firstUpper = true, injection = null) {
    if (typeof placeholder !== 'string') {
        console.error('_locale was called with a', typeof placeholder, 'value');
        return '';
    }
    let string = translate(placeholder);

    if (typeof injection !== 'undefined') {
        if (Array.isArray(injection)) {
            string = string.replace(/%s|%d/g, () => injection.shift())
        } else {
            string = string.replace(/%s|%d/g, injection);
        }
    }

    if (firstUpper) {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }
    
    return string
}