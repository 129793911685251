<template>
<div class="survey-question">
    <label :for="`question-${ element.questionId }`" v-html="element.question"></label>
    <div class="survey-input-container">
        <WnChoices 
            :value="value"
            :options="industries"
            @input="onValueChange" />
    </div>
</div>
</template>

<script>
export default {
    emits: ['valueChange'],
    props: {
        element: {
            type: Object,
            required: true,
        },
        initialValue: {
            required: false,
        },
    },
    data: () => ({
        industries: [],
        value: null,
        extra: {
            comments: '',
        },
    }),
    computed: {
    },
    methods: {
        onValueChange(value) {
            const { questionId, questionType } = this.element
            
            this.value = value
            this.$emit('valueChange', { questionId, value, extra: {}, questionType })
        },
    },
    async mounted() {
        if (this.initialValue) {
            this.value = this.initialValue
        }
        const appauth = this.$_appauth ? `&appauth=${ this.$_appauth }` : ''

        const { data } = await this.$_http({
            url: `${ this.$_baseUrl || '' }/?action=industryclasses${ appauth }`, 
            type: 'POST',
            data: {
                forSelect2: true
            }
        })

        this.industries = data.map(row => ({
            ...row,
            id: `${ row.industryLetter }${ row.industryId }`,
            text: `${ row.industryLetter }${ row.industryId } ${ row.industryName }`,
        }))
    }
}
</script>