export default [  
   {  
      "text":"Aktia",
      "value":"HELSFIHH"
   },
   {  
      "text":"Apix Messaging Oy",
      "value":"003723327487"
   },
   {  
      "text":"Basware Oyj",
      "value":"BAWCFI22"
   },
   {  
      "text":"CGI",
      "value":"003703575029"
   },
   {  
      "text":"Danske Bank Oyj",
      "value":"DABAFIHH"
   },
   {  
      "text":"Handelsbanken",
      "value":"HANDFIHH"
   },
   {  
      "text":"HighJump AS",
      "value":"00885790000000418"
   },
   {  
      "text":"Open Text Oy",
      "value":"003708599126"
   },
   {  
      "text":"Maventa",
      "value":"003721291126"
   },
   /*
   {  
      "text":"Maventa(pankkiverkkoon)",
      "value":"DABAFIHH"
   },
   */
   {  
      "text":"Netbox Finland",
      "value":"003726044706"
   },
   {  
      "text":"Nordea",
      "value":"NDEAFIHH"
   },
   {  
      "text":"Oma säästöpankki Oyj",
      "value":"ITELFIHH"
   },
   {  
      "text":"OP-Pohjola-ryhmä",
      "value":"OKOYFIHH"
   },
   {  
      "text":"OpusCapita Solutions Oy",
      "value":"E204503"
   },
   {  
      "text":"Pagero",
      "value":"003723609900 PAGERO"
   },
   {  
      "text":"POP Pankki",
      "value":"POPFFI22"
   },
   {  
      "text":"Posti Messaging Oy",
      "value":"003710948874"
   },
   {  
      "text":"PostNord Strålfors Oy",
      "value":"003701150617"
   },
   {  
      "text":"Ropo Capital Oy",
      "value":"003714377140"
   },
   {  
      "text":"Säästöpankit",
      "value":"003722387525"
   },
   {  
      "text":"Telia Finland Oyj",
      "value":"003714756079"
   },
   {  
      "text":"Tieto Oyj",
      "value":"003701011385"
   },
   {  
      "text":"Åland Post Ab",
      "value":"003722207029"
   }
]