<template>
<div class="survey-userconsent">
    <div class="survey-input-container">
        <div class="survey-checkbox__option">
            <label>
                <input type="checkbox" 
                    v-model="value"
                    @change="onValueChange" />
                <span v-html="label"></span>
            </label>
        </div>
    </div>
</div>
</template>

<script>
export default {
    emits: ['valueChange'],
    props: {
        element: {
            type: Object,
            required: true,
        },
        initialValue: {
            required: false,
        },
    },
    data: () => ({
        value: false,
        label: '',
    }),
    methods: {
        onValueChange(evt) {
            const { type } = this.element

            const value = {
                type: 'consent',
                acceptConsent: evt.target.checked
            }

            this.$emit('valueChange', { questionId: 'consent', value, questionType: type })
        },
    },
    mounted() {
        const settings = window.wnSurveySettings || {}

        this.label = settings.consentLabel || this.$_locale('I accept the terms of use')

        if (this.initialValue) {
            this.value = this.initialValue
        }

        this.$nextTick(
            () => this.onValueChange({ target: { checked: this.value } })
        )
    }
}
</script>