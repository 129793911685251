<template>
<div class="survey-question"
    :class="[ `survey-checkbox__${ element.settings.inputType }` ]">
    <label :for="`question-${ element.questionId }`" v-html="element.question"></label>
    <div class="survey-input-container">
        <div class="survey-checkbox__option"
            v-for="opt in options"
            :key="`option-${ element.questionId }-${ opt.value }`">
            <label>
                <input v-if="element.settings.inputType === 'radio'"
                    type="radio" 
                    v-model="value"
                    :value="opt.value" 
                    :name="'survey-radio-'+element.questionId"
                    @change="onValueChange" />
                <input v-if="element.settings.inputType === 'checkbox'"
                    type="checkbox" 
                    v-model="value"
                    :value="opt.value" 
                    @change="onValueChange" />
                <span v-html="opt.text"></span>
            </label>
            
            <div class="survey-specifying-field" 
                v-if="checkIfSpecifyingEnabled(opt)">
                <input type="text" 
                    :disabled="checkIfSpecifyingFieldDisabled"
                    @input="onSpecifyingFieldChange($event, opt)" />
                <span v-html="getSpecifyingField(opt)"></span>
            </div>
        </div>
        
        <div class="survey-checkbox__option-else" 
            v-if="element.settings.extra.else && element.settings.extra.else.show">
            <div class="">                    
                <input v-if="element.settings.inputType === 'radio'"
                    type="radio" 
                    v-model="value"
                    value="elseSelection" 
                    :name="'survey-radio-'+element.questionId"
                    @change="onValueChange" />
                <input v-if="element.settings.inputType === 'checkbox'"
                    type="checkbox" 
                    v-model="value"
                    value="elseSelection"
                    @change="onValueChange" />
            </div>
            <div class="">
                <input 
                    type="text"
                    v-model="extra.else.answer"
                    :placeholder="element.settings.extra.else.title"
                    @input="onElseChange" />
            </div>
        </div>
    </div>
    <ExtraComments 
        v-if="element.settings.extra.comments && element.settings.extra.comments.show"
        :element="element"
        @valueChange="onCommentChange" />
</div>
</template>

<script>
import ExtraComments from './ExtraComments.vue'

export default {
    emits: ['valueChange'],
    props: {
        element: {
            type: Object,
            required: true,
        },
        initialValue: {
            required: false,
        },
    },
    components: {
        ExtraComments,
    },
    data: () => ({
        value: null,
        extra: {
            comments: '',
            else: {
                checked: false,
                answer: '',
            },
            specifyingField: {}
        },
    }),
    computed: {
        options() {
            const { settings, options } = this.element

            if (settings.useObjects) {
                return options
            }

            return options.map(row => ({
                text: row,
                value: row
            }))
        },
        checkIfSpecifyingFieldDisabled() {
            const { settings } = this.element

            if (typeof settings.fixedQuantity === 'string') {
                return settings.fixedQuantity.length > 0
            } else if (typeof settings.fixedQuantity === 'number') {
                return settings.fixedQuantity > 0
            }
            
            return false
        },
        checkIfSpecifyingEnabled() { 
            const { settings } = this.element

            return (opt) => {
                if (!settings.extra.specifyingField) return false;
                
                if (settings.inputType === 'radio'){
                    return this.value === opt.value
                }

                if (settings.inputType === 'checkbox' && this.value !== null) {
                    return this.value.includes(opt.value)
                }

                return true
            }
        },
        getSpecifyingField() {
            const { settings } = this.element
            const { extra } = settings
            const specifyingFieldLabel = typeof extra.specifyingFieldLabel !== 'undefined' ? extra.specifyingFieldLabel : this.$_locale('pcs', false)
            
            return (opt) => {                
                if (typeof specifyingFieldLabel === 'string') {
                    return specifyingFieldLabel;
                } else {
                    // must be an object then :)
                    return specifyingFieldLabel[opt] || this.$_locale('pcs', false);
                }
            }
        },
    },
    methods: {
        handleEmit() {
            const { questionId, settings, questionType } = this.element
            let value = structuredClone(this.value)

            if (settings.inputType === 'radio') {
                if (value === 'elseSelection') {
                    this.extra.else.checked = true
                    value = this.extra.else.answer
                }        
                // this component always wraps the value in an array
                value = [ value ]
            } else if (settings.inputType === 'checkbox') {
                const index = value.findIndex(val => val === 'elseSelection')

                if (index > -1) {
                    this.extra.else.checked = true
                    value.splice(index, 1, this.extra.else.answer)
                }                
            }
            
            this.$emit('valueChange', { 
                questionId, 
                value,
                extra: this.extra, 
                questionType
            })
        },
        onValueChange() {
            this.$nextTick(this.handleEmit())
        },
        onCommentChange({ value }) {
            this.extra.comments = value
            this.$nextTick(this.handleEmit())
        },
        onSpecifyingFieldChange(evt, option) {
            const { value } = evt.target

            this.extra.specifyingField[option] = value
            this.$nextTick(this.handleEmit())
        },
        onElseChange(evt) {
            const { value } = evt.target

            this.extra.else.answer = value
            this.$nextTick(this.handleEmit())
        },
    },
    mounted() {
        const { settings } = this.element

        if (settings.inputType === 'checkbox') {
            this.value = []
        }

        if (this.initialValue) {
            this.value = this.initialValue
        }
    }
}
</script>