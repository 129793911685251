<template>
<div class="survey-question">
    <label :for="`question-${ element.questionId }`" v-html="element.question"></label>
    <div class="survey-input-container">
        <textarea 
            :value="value" 
            maxlength="8000"
            @input="onValueChange">
        </textarea>
    </div>
</div>
</template>

<script>
export default {
    emits: ['valueChange'],
    props: {
        element: {
            type: Object,
            required: true,
        },
        initialValue: {
            required: false,
        },
    },
    data: () => ({
        value: '',
    }),
    methods: {
        // handle value and emit valueChange
        onValueChange(evt) {
            const { questionId, questionType } = this.element
            const { value } = evt.target

            this.value = value
            this.$emit('valueChange', { questionId, value, questionType })
        },
    },
    mounted() {
        if (this.initialValue) {
            this.value = this.initialValue
        }
    }
}
</script>