<template>
<div class="survey-question">
    <label :for="`question-${ element.questionId }`" v-html="element.question"></label>
    <div class="survey-input-container"
        :class="{
            'input-group': identifierText
        }">
        <template v-if="element.fieldType === 'text'">
            <input 
                :type="element.settings.textType || 'text'" 
                :placeholder="$_locale('your answer comes here')"
                maxlength="255" 
                :value="value"
                @input="onValueChange" />
        </template>
        <template v-else-if="element.fieldType === 'date'">
            <input 
                type="date" 
                :value="value" 
                class="survey-date"
                @input="onValueChange" />
        </template>
        <template v-else-if="element.fieldType === 'numeric'">
            <input type="text" 
                class="survey-numeric" 
                :value="value"
                :placeholder="$_locale('your answer comes here')"
                @blur="onValueChange" />
            <span 
                v-if="identifierText"
                class="input-group-addon">
                {{ identifierText }}
            </span>
        </template>
    </div>
</div>
</template>

<script>
import { getTimestamp } from '../helpers/surveyService.js'

export default {
    emits: ['valueChange'],
    props: {
        element: {
            type: Object,
            required: true,
        },
        initialValue: {
            required: false,
        },
    },
    data: () => ({
        value: '',
        identifierText: null,
    }),
    methods: {
        // handle value and emit valueChange
        onValueChange(evt) {
            const { questionId, fieldType } = this.element
            const { value } = evt.target

            if (fieldType === 'numeric') {
                if (value.trim()) {
                    setTimeout(() => {
                        const numericValue = this.$_formatCurrency(value, false)
                        this.value = this.$_formatCurrency(numericValue)
                        this.$emit('valueChange', { questionId, value: numericValue, questionType: fieldType })
                    }, 33)
                } else {
                    this.$emit('valueChange', { questionId, value: 0, questionType: fieldType })
                }
            } else if (fieldType === 'date') {
                const test = (new Date(value)).toString()
                // first test that the value can form a date
                if (test !== 'Invalid Date') {
                    this.$emit('valueChange', { questionId, value: getTimestamp(value), questionType: fieldType })
                    this.value = value
                }                
            } else {
                this.$emit('valueChange', { questionId, value, questionType: fieldType })
            }            
        },
    },
    mounted() {
        const { settings, fieldType } = this.element

        if (fieldType === 'numeric') {
            this.value = 0
        } else if (fieldType === 'date') {
            // ???
        }

        if (this.initialValue) {
            this.value = this.initialValue
        }

        if (typeof settings.identifierText === 'string') {
            this.identifierText = settings.identifierText;
        }
    }
}
</script>