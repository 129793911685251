<template>
<div class="wnupload-container">
    <div
        class="dropzone"
        :class="{ 'drag-over': isDragging }"
        @dragenter.prevent="onDragEnter"
        @dragleave.prevent="onDragLeave"
        @drop.prevent="onDrop"
        @click="triggerFileInput">
        
        <button v-if="files.length > 0" class="clear-button" @click.stop="clearFiles">
            {{ $_locale('clear') }}
        </button>
        
        <p v-if="files.length === 0">
            {{ $_locale('drop files here or click this box to select files') }}
        </p>
        
        <WnFileList 
            v-if="files.length > 0"
            :files="files" />
    </div>
    <input
        type="file"
        ref="fileInput"
        multiple
        :accept="acceptFormats.join(',')"
        @change="onFilesSelected"
        style="display: none"
    />
    <button v-if="viewState === VIEW_STATE.idle"
        @click="uploadFiles" 
        :disabled="files.length === 0">
        {{ $_locale(`upload file${ files.length > 1 ? 's' : '' }`) }}
    </button>
</div>
</template>
    
<script>
import WnFileList from './WnFileList.vue'

const VIEW_STATE = {
    idle: 1,
    processing: 2,
    ready: 3,
    error: 4,
}

export default {
    components: {
        WnFileList,
    },
    props: {
        acceptFormats: {
            type: Array,
            default: () => ['*/*'], // Default accepts all formats
        },
    },
    data: () => ({
        VIEW_STATE,
        viewState: VIEW_STATE.idle,
        files: [],
        isDragging: false,
    }),
    methods: {
        triggerFileInput() {
            this.$refs.fileInput.click()
        },
        onDragEnter() {
            this.isDragging = true; // Set isDragging to true when dragging over
        },
        onDragLeave(event) {
            // Check if leaving the dropzone area
            if (event.relatedTarget === null || !this.$el.contains(event.relatedTarget)) {
                this.isDragging = false; // Set isDragging to false when leaving the dropzone
            }
        },
        onDrop(event) {
            const droppedFiles = Array.from(event.dataTransfer.files)
            this.processFiles(droppedFiles);
        },
        onFilesSelected(event) {
            const selectedFiles = Array.from(event.target.files)
            this.processFiles(selectedFiles);
        },
        processFiles(fileList) {
            // Filter out the files that don't match the accepted formats
            const acceptedFiles = fileList.filter((file) =>
                this.acceptFormats.includes('*/*') ||
                this.acceptFormats.includes(file.type)
            )

            this.files.push(...acceptedFiles)
        },
        clearFiles() {
            this.files = []
        },
        async uploadFiles() {
            const activeHost = this.$store.getters['common/activeHost']
            const { ajaxUrl, appauth } = activeHost
            this.viewState = VIEW_STATE.processing

            try {
                const files = new FormData()

                this.files.forEach((file) => {
                    files.append('files', file)
                })
                
                const { data } = await this.$_http({
                    method: 'POST',
                    url: `${ ajaxUrl }/?action=uploadfiles&appauth=${ appauth }`, 
                    data: {
                        files
                    },
                })

                if (Array.isArray(data)) {
                    this.$emit('change', data)
                }

                // Clear the files list after successful upload
                this.files = [];
                this.viewState = VIEW_STATE.ready
            } catch (error) {
                console.error('Upload failed', error)
                this.$emit('error', error)
                this.viewState = VIEW_STATE.error
            }
        },
    },
}
</script>
    
<style scoped>
    .wnupload-container {
        position: relative;
    }
    
    
    
    .dropzone.drag-over {
        background: #e0e0e0;
    }
    
    .clear-button {
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 5px 10px;
        background: red;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }
    
    .clear-button:hover {
        background: darkred;
    }
</style>
    