<template>
<div class="file-list">
    <div v-for="(file, index) in files" :key="index" class="file-item">
        <div class="file-icon">
            <span class="glyphicon" :class="{
                'glyphicon-file': !isImage(file),
                'glyphicon-picture': isImage(file)
            }"></span>
        </div>
        <div class="file-name">
            {{ file.name }}
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        files: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        isImage(file) {
            // Check if the file is an image type
            return file.type.includes('image');
        }
    }
}
</script>

<style scoped>
.file-list {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    /* Space between items */
}

.file-item {
    flex: 1 1 100px;
    /* Flex-grow, flex-shrink, and min-width */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.file-icon {
    font-size: 30px;
    text-align: center;
    margin-bottom: 5px;
    color: #00796b;
    /* Change color to make it look modern */
}

.file-name {
    font-size: 12px;
    text-align: center;
    color: #555;
}
</style>