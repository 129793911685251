<template>
<div>
	<ion-app v-if="ready">
		<ion-router-outlet />
	</ion-app>
</div>
</template>

<script>
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { App } from '@capacitor/app';
import { Network } from '@capacitor/network';
import { SplashScreen } from '@capacitor/splash-screen';
import { run, /*addRunner*/ } from '@/store/AppState.js'
import settings from "./WiseSettings";
import useWiseGolf from '@/WiseEcom/mixins/WiseGolf.js'
import { usePushNotifications } from './helpers/usePushNotifications'
import { useSystemNotifications } from './helpers/useSystemNotifications'

export default defineComponent({
	setup() {
		const { golfInit } = useWiseGolf()
		usePushNotifications({ setupWatchers: true })
		useSystemNotifications()

		return {
			golfInit,
		}
	},
	//mixins: IMPORTANT! LOAD APP MIXINS IN MAIN.JS
	components: {
		IonApp,
		IonRouterOutlet,
	},
	data: () => ({
		ready: false,
		networkStatus: true,
		networkListener: null,
		intervalBackgroundGPS: null,
	}),
	computed: {
		...mapGetters({ 
			activeHost: 'common/activeHost',
			loggedIn: 'user/loggedIn',
		}),
		mainClass() {
			const activeHost = this.activeHost;
			let classes = [];

			if (activeHost.className) {
				classes = activeHost.className.split(' ')
			}

			if (settings.defaultClassName) {
				classes = [
					...classes, 
					...settings.defaultClassName.split(' ') 
				]
			}
			
			return Array.from(new Set(classes));
		}
	},
	watch: {
		networkStatus() {
			if(this.networkStatus.connected === false) {
				// If network status changes to not connected show error toast
				this.showToast(this.$t("errors.noNetwork"), this.$t("errors.noNetworkText"), "danger")
			}
		},
		async loggedIn() {
			console.log(`APP.vue loggedIn changed: ${this.loggedIn}`)
			if (this.loggedIn === true) {

				if (settings.useGymFeatures) {
					await this.gymInit();
				}

				if (settings.useGolfFeatures) {
					await this.golfInit();
				}

				if (settings.useReservationWebsocket) {
					await this.initWebsocket();
				}
			}
		}
	},
	async mounted() {		
		this.mainClass.forEach(row => document.body.classList.add(row))

		if(Capacitor.getPlatform() === "ios") {
			// On iOS show done button when keyboard is open
			await Keyboard.setAccessoryBarVisible({ isVisible: true });
		}

		window.addEventListener('ionKeyboardDidShow', () => {
			document.querySelector('#app').classList.add('keyboard-visible')
		})

		window.addEventListener('ionKeyboardDidHide', () => {
			document.querySelector('#app').classList.remove('keyboard-visible')
		})

		window.addEventListener('resize', () => {
			const isMobile = window.innerWidth <= 768;
			
			this.$store.commit('res_common/setMobile', isMobile)
		}, { passive: true })
		// addRunner(
		// 	() => console.log('tämäpä ajetaan kun app state falsessa'),
		// 	{ isActive: false }
		// )
		// Check loggedIn status inside of the runner function!!
		// addRunner(
		// 	() => console.log('herätelläänpä appia'),
		// 	{ isActive: true }
		// )

		App.addListener('appStateChange', async (state) => {
			if (state.isActive === true) {
				run({ isActive: true })
			} else {
				run({ isActive: false, loggedIn: false })
			}
		});

		// Monitor network status (used to inform user when internet connection lost)
		this.networkListener = Network.addListener('networkStatusChange', (status) => {
			this.networkStatus = status
		});
		this.networkStatus = await Network.getStatus();

		// If user is logged In
		if(this.loggedIn === true) {

			if (settings.useGymFeatures) {
				await this.gymInit();
			}
			
			if (settings.useGolfFeatures) {
				await this.golfInit();
			}

			if (settings.useReservationWebsocket) {
				await this.initWebsocket();
			}
		}

		this.ready = true;
		await SplashScreen.hide();
		// APP mounted() ends
	},
});
</script>
