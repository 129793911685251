import { mapState, mapActions, mapGetters } from "vuex";
import axios from "axios";
import { loadingController } from "@ionic/vue";
import HostMixin from './HostMixin.js';

export default {
	mixins: [ HostMixin ],
    data: () => ({
        form: {
            username: "",
            password: "",
        },
        forgotPassword: false,
        forgotPasswordSuccess: false,
        submitDisabled: false,
    }),
	computed: {
		...mapState({
			user: state => state.user.user,
			settings: state => state.common.settings,
			selectedHost: state => state.common.selectedHost,
		}),
		...mapGetters({activeHost: "common/activeHost"}),
	},
	methods: {
		...mapActions({
			logIn: "user/logIn",
			getDoorAndLocationDataFromServer: "door/getDoorAndLocationDataFromServer"
		}),

		// Submits login or password reset
		async submitLoginForm() {
			if (this.submitDisabled) return;

			// If no host selected show error
			if(!this.selectedHost) {
				this.addErrorAlert(this.$t(this.settings.noHostSelectedText || 'errors.loginError.noHostSelected'), this.forgotPassword ? this.$t("errors.loginError.forgetTitle") : this.$t("errors.loginError.title"))
				return
			}

			if(this.forgotPassword === true) {
				this.submitForgotPassword()
				return
			}

			// If username or password empty show error
			if(!this.form.username.trim() && !this.form.password.trim()) {
				this.addErrorAlert(this.$t("errors.loginError.userAndPassRequired"), this.$t("errors.loginError.userAndPassRequiredTitle"))
				return
			}

			await this.loadingOverlay();

			if (this.$route.query.sessionToken) {
				this.form.golfpisteToken = this.$route.query.sessionToken;
			}

			if (this.$route.query.userToken) {
				this.form.golfpisteUser = this.$route.query.userToken;
			}
			
			const data = await this.logIn(this.form)

			if (data.success === false) {
				this.loading.dismiss();

				return this.$_wiseAlert({
					header: this.$t('login.errorTitle'),
					message: data.error,
					buttons: ['OK']
				})
			}
			console.log(`%cLogin success: `, 'color: lime; padding: 5px 0', data)
			
			if (this.settings.useGymFeatures) {
				try {
					// Get door and location data from server
					await this.getDoorAndLocationDataFromServer()
				} catch (error) {
					
					this.addErrorAlert(this.$t("errors.loginError.doorDataError"), this.$t("error"))
					console.error(error)
				}
			}
			this.$emit('loginComplete', data)

			// Login success redirect user to route
			if(this.urlToOnSuccess) {
				this.$router.push(this.urlToOnSuccess)
			}
			
			this.loading.dismiss();
		},

		async submitForgotPassword() {
			// If username field is empty show error
			if(this.form.username === "") {
				this.addErrorAlert(this.$t("errors.loginError.noUsername"), this.$t("errors.loginError.forgetTitle"))
				return
			}

			try {
				// QUESTION How to set locale to send reset email?
				this.submitDisabled = true;
				const { data } = await axios.post(
					`${this.activeHost.baseUrl}?action=forgottenpassword&mode=requestnew&appauth=${this.activeHost.appauth}`,
					JSON.stringify({email: this.form.username}),
					{ headers: { "Content-Type": "application/json" } }
				)
				this.submitDisabled = false

				if(data.success === true) {
					// Show password reset success text
					this.forgotPasswordSuccess = true;
					this.$_wiseAlert({
						header: this.$t("errors.loginForgotSuccessTitle"),
						message: this.$t("errors.loginForgotSuccess"),
						backdropDismiss: false,
						buttons: ["Ok"]
					})
				}

				if(data.success === false) {
					this.addErrorAlert(this.$t("errors.loginForgotError.emailNotFound"), this.$t("errors.loginError.forgetTitle"))	
				}

			} catch (error) {
				this.submitDisabled = false
				console.error(error)
				this.addErrorAlert(this.$t("errors.loginForgotError.unknown"), this.$t("errors.loginError.forgetTitle"))
			}
		},

		async loadingOverlay() {
			this.loading = await loadingController.create({
				cssClass: "wise-login-overlay",
				message: this.$t("login.loading"),
			});
			await this.loading.present();
		},

		async addErrorAlert(errorMessage, title = this.$t("error"), forgotPassword = false) {	
			const buttonsConfigOk = ["Ok"]
			const buttonsConfigForgot = ["Ok"/*,{text: 'Unohtuiko salasana?', handler: () => this.forgotPassword = true},*/]

			this.$_wiseAlert({
				header: title,
				message: errorMessage,
				buttons: forgotPassword ? buttonsConfigForgot : buttonsConfigOk
			})
		},
	},
}