<template>
<div class="survey-question">
    <label :for="`question-${ element.questionId }`" v-html="element.question"></label>
    <div class="survey-input-container">
        <WnUpload @change="onValueChange" />
    </div>
</div>
</template>

<script>
export default {
    emits: ['valueChange'],
    props: {
        element: {
            type: Object,
            required: true,
        },
        // this type really does not have initial value?
        // maybe if we printed already loaded files?
        initialValue: {
            required: false,
        },
    },
    data: () => ({
        value: null,
        identifierText: null,
    }),
    methods: {
        // handle value and emit valueChange
        onValueChange(files) {
            const { questionId, fieldType } = this.element

            this.$emit('valueChange', { questionId, value: files, questionType: fieldType })           
        },
    },
    mounted() {
    }
}
</script>