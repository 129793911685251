<template>
<div>
    <h5>Element not yet implemented:</h5>
    <pre><code>{{ element }}</code></pre>
</div>
</template>

<script>
export default {
    props: {
        element: {
            type: Object,
            required: true,
        }
    },
}
</script>