import { ref, computed, watch } from 'vue'
import axios from 'axios';
import store from '@/store';
import settings from '@/WiseSettings.js'

const selectedClub = ref(null)
const associatedClubs = ref(
    settings.hosts.filter(row => row.golfClubId)
    .map(row => ({
        ...row,
        urls: {
            baseUrl: row.baseUrl,
            ajaxUrl: row.ajaxUrl,
            adminUrl: row.adminUrl,
            restUrl: row.restUrl,
            ecomUrl: row.ecomUrl,
        }
    }))
)
const accessControlQuantity = ref(0)
const accessControlId = ref(null)
const rangeDispencerProducts = ref([])
const rangeDispencesDevices = ref([])
const rangeDispencerActive = computed(
    () => rangeDispencesDevices.value.filter(row => row.active).length > 0
)
const rangeDispencerAvailable = computed(
    () => rangeDispencesDevices.value.length > 0
)

const setSelectedClub = (value) => selectedClub.value = value;
const setAssociatedClubs = (value) => associatedClubs.value = value;
const setAccessControlQuantity = (value) => accessControlQuantity.value = value;
const setAccessControlId = (value) => accessControlId.value = value;
const setRangeDispencerDevices = (value) => rangeDispencesDevices.value = value;
const setRangeDispencerProducts = (value) => rangeDispencerProducts.value = value;

async function getAssociatedClubs(settings) {
    try {
        return await axios({
            url: `${ settings.restUrl }/golf/club/associated/`,
            method: 'GET',
            withCredentials: true,
        })
    } catch (error) {
        console.error(error)
    }

    return [];
}

async function getUserGolfReservations(settings) {
    try {
        return await axios({
            method: 'GET',
            url: `${ settings.ajaxUrl }?reservations=getusergolfreservations&appauth=${ settings.appauth }`,
        })
    } catch (error) {
		console.error(error)
	}

    return [];
}

async function getUserOtherReservations(settings) {
    try {
        return await axios({
            method: 'GET',
            url: `${ settings.ajaxUrl }?reservations=getuserreservations&appauth=${ settings.appauth }`,
        })
    } catch (error) {
		console.error(error)
	}

    return [];
}

function resolveSelectedClubSettings() {
    const selectedClubName = selectedClub.value || associatedClubs.value[0].name;
    const club = associatedClubs.value.filter(row => row.name === selectedClubName)[0];
    const { appauth, urls, articleCategory } = club;
    const { ajaxUrl } = urls;
    const settings = {
        ajaxUrl,
        appauth,
    }
    
    if (articleCategory) {
        settings.categoryName = articleCategory;
    }
    
    return settings;
}

async function loadRangeDispencer() {
    try {
        const EcomService = store.getters['res_ecom/EcomService']
        const { data } = await EcomService.initRangeDispencer();
        const { success, products, devices, accessControlQuantity, accessControlId } = data;
    
        if (success) {
            if (Array.isArray(devices)) {
                setRangeDispencerDevices(devices)
            }

            if (Array.isArray(products)) {
                setRangeDispencerProducts(products)
            }
            
            setAccessControlQuantity(accessControlQuantity)
            setAccessControlId(accessControlId)
            return;
        }
    } catch(e) {
        console.warn('loadRangeDispencer', e)
    }

    setRangeDispencerProducts([])
    setRangeDispencerDevices([])
    setAccessControlQuantity(0)
    setAccessControlId(null)
}

export default function useGolfState() {
    const loggedIn = computed(() => store.getters['user/loggedIn'])
    const activeClub = computed(() => {
        const [ club ] = associatedClubs.value.filter(row => row.name === selectedClub.value);
                
        if (club) {
            return club;
        }
    
        return associatedClubs.value[0];
    })

    watch(loggedIn, (val) => {
        if (!val) {
            setSelectedClub(null)
            setRangeDispencerProducts([])
            setRangeDispencerDevices([])
            setAccessControlQuantity(0)
            setAccessControlId(null)
        }
    })

    return {
        selectedClub,
        associatedClubs,
        rangeDispencerAvailable,
        rangeDispencerActive,
        accessControlId,
        accessControlQuantity,
        rangeDispencerProducts,
        rangeDispencesDevices,
        activeClub,
        setSelectedClub,
        setAssociatedClubs,
        setRangeDispencerDevices,
        getAssociatedClubs,
        getUserGolfReservations,
        getUserOtherReservations,
        resolveSelectedClubSettings,
        loadRangeDispencer,
    }
}