<template>
<div class="survey-question">
    <label :for="`question-${ element.questionId }`" v-html="element.question"></label>
    <div class="survey-input-container">
        <WnChoices 
            :value="value"
            :options="countries"
            :multiple="true"
            @input="onValueChange" />
    </div>
</div>
</template>

<script>
export default {
    emits: ['valueChange'],
    props: {
        element: {
            type: Object,
            required: true,
        },
        initialValue: {
            required: false,
        },
    },
    data: () => ({
        countries: [],
        value: [],
        extra: {
            comments: '',
        },
    }),
    computed: {
    },
    methods: {
        onValueChange(value) {
            const { questionId, questionType } = this.element

            this.value = value
            this.$emit('valueChange', { questionId, value, extra: {}, questionType })
        },
    },
    async mounted() {
        if (this.initialValue) {
            this.value = this.initialValue
        }

        this.countries = await this.$_getCountries()
    }
}
</script>