<template>
<div class="survey-element-error">
    <span v-if="errorMessage">{{ $_locale(errorMessage) }}</span>
    <span v-else>{{ $_locale('required field') }}</span>
</div>
</template>

<script>
export default {
    props: {
        errorMessage: {
            type: String,
        }
    },
}
</script>