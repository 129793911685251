/**
 * Adjust date to greenwichtime
 * 
 * @param {Date} dateObj 
 * @returns {Date}
 */
export function asGreenwichTime(dateObj) {
    const offset = dateObj.getTimezoneOffset() * -1
    return new Date(dateObj.getTime() + (offset * 60000))
}

/**
 * 
 * @param {Date} dateObj 
 * @returns {String}
 */
export function getTimestamp(dateObj = new Date()) {
    const dateTime = asGreenwichTime(dateObj).toISOString()
    const [ date, timezone ] = dateTime.split('T')
    const [ time ] = timezone.split('.')

    return `${ date } ${ time }`
}

export function setError(errors, questionId, show, message) {
    errors[questionId] = {
        show,
        message
    }
}

export function validateCondition(condition, target) {
    let valid = condition.rule.includes(target.value)
    
    if (target.type === 'checkbox' || target.type === 'products') {
        let values = target.value || [];
        let rule = structuredClone(condition.rule)
        
        if (target.type === 'products') {
            rule = rule.map(row => {
                if (typeof row === 'object') {
                    return row.productId
                }
    
                return row
            })
        }
        // legacy way, is this needed??
        if (typeof target.value === 'string') {
            values = target.value.split(',');
        }
        
        for (let i = 0; i < values.length; i++) {
            valid = rule.includes(values[i])

            if (valid) {
                break;
            }                
        }
    }

    return condition.reverse ? !valid : valid;
}

export function validateConditions(conditions, answers) {
    let valid = true;
    
    if (!Array.isArray(conditions)) return true;

    for (let i = 0; i < conditions.length; i++) {
        const condition = conditions[i]
        const target = answers['q-' + condition.questionId]

        if (!target || !validateCondition(condition, target)) {
            valid = false;
            break;
        }
    }

    return valid;
}

export function handleRepeaterAnswers(answers) {
    Object.keys(answers).forEach((key) => {
        const answer = answers[key];
        
        if (typeof answer.questionId === 'string') {
            if (answer.questionId.includes('-tpid-')) {
                answer.questionId = -(-( answer.questionId.split('-tpid-')[0] ));
            }
        }
    })
}

export function doAnswerInjection(inject, elements, answers) {
    const [ contactDetails ] = elements.filter((element) => element.type === 'contactDetails')
    const [ companyDetails ] = elements.filter((element) => element.type === 'companyDetails')
    
    if (inject['contactDetails']) {
        if (contactDetails) {
            const options = ['firstName','familyName','email'].concat(contactDetails.options);
            
            // replace address with real values
            if (options.includes('address')) {
                options.splice.apply(options, [options.indexOf('address'), 1].concat(['streetAddress','postalCode','city']));
            }
            
            Object.keys(inject['contactDetails']).forEach((key) => {
                if (options.includes(key) === false) {
                    delete inject['contactDetails'][key];
                }
            })
        } else {
            // clear injections
            delete inject['contactDetails'];
        }
    }
    
    if (inject['companyDetails']) {
        if (companyDetails) {
            const options = companyDetails.options;
            
            Object.keys(inject['companyDetails']).forEach((key) => {
                if (options.includes(key) === false) {
                    delete inject['companyDetails'][key];
                }
            })
        } else {
            // clear injections
            delete inject['companyDetails'];
        }
    }
    
    Object.keys(inject).forEach(questionId => {
        const values = inject[questionId]
        
        if (!answers[questionId]) {
            answers[questionId] = {};
        }                
        
        Object.keys(values).forEach(key => {
            const value = values[key]

            answers[questionId][key] = value;
        })
    })
}

export function preHandleTrainerRepeaters(elements, settings) {
    let findTrainerRepeaters = true;
    
    while(findTrainerRepeaters) {
        const index = elements.findIndex((element) => element.type === 'trainerRepeater');

        if (index > -1) {
            const trainers = settings.eventTrainers || [];
            const replaceElements = [];
            
            trainers.forEach((trainer, i) => {
                replaceElements.push({ 
                    surveyElementId: 'separator-'+i, 
                    type: 'separator' 
                });
                replaceElements.push({ 
                    surveyElementId: 'subheading-'+i, 
                    type: 'text',
                    content: `<h4 style="font-size: 16px;margin-bottom: 0;margin-top: 16px;">${ trainer.firstName } ${ trainer.familyName }</h4>`
                });
                
                elements[index].elements.forEach((element) => {
                    const copied = Object.assign({}, element);
                    
                    if (copied.type === 'question') {
                        copied.questionId = copied.questionId + '-tpid-' + trainer.personId;
                        copied.inject = { trainerPersonId: trainer.personId };
                    }
                    
                    replaceElements.push(copied);
                })
            })
            
            elements.splice.apply(elements, [index, 1].concat(replaceElements));
        } else {
            findTrainerRepeaters = false;
        } 
    }
    
    return elements;
}

// now it's only trainer repeater but in the future there might be more
export function preHandleElements(elements) {
    elements = preHandleTrainerRepeaters(elements);
    
    return elements;
}

export function removeHiddenAnswers(elements, answers, remove) {
    // remove answers from hidden questions
    elements.forEach((element) => {
        if (remove && element) {
            if (element.questionId) {
                delete answers['q-' + element.questionId]
            } else {
                delete answers[element.type]
            }
        }
            
        if (Array.isArray(element.elements) && element.type === 'conditionalArea') {
            removeHiddenAnswers(
                element.elements, 
                answers,
                !validateConditions(element.conditions, answers)
            )
        }            
    })
}

export function handleMatchAnswers(answers, settings, mapGames) {
    Object.keys(answers).forEach((key) => {
        const answer = answers[key];
        
        if (answer.type === 'matches') {
            if (!Array.isArray(answer.value)) {
                answers[key].value = settings.matches
                .filter((game) => game.activityId == answer.value)
                .map(mapGames)
            }
        }
    })
}

export function validateEmail(email) {
    let re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

    return re.test(email);
}

export function validate(page, answers, errors, answerIdAddition = false) {
    let validPage = true
    let checkConsent = false
    
    page.forEach(element => {
        let validElement = true
        let { required } = element
        
        switch (element.type) {
            case 'question':
                validElement = validateQuestion(element, answers, errors, answerIdAddition)
            break;
            case 'attendeeDetail':
            case 'contactDetails':
                const options = ['firstName','familyName','email'].concat(element.options)
                let answerIdentifier = element.type;
                
                if (element.answerIdentifier) {
                    answerIdentifier = element.answerIdentifier;
                }
                
                // replace address with real values
                if (options.includes('address')) {
                    options.splice.apply(options, [options.indexOf('address'), 1].concat(['streetAddress','postalCode','city']))
                }
                                    
                if (answers[answerIdentifier]) {
                    options.forEach((key) => {
                        const data = answers[answerIdentifier][key];
                        
                        if (typeof data === 'string' && !!data.trim())
                            required = true;
                    })
                }

                if (required) {
                    validElement = validateContactDetails(element, answers, errors, answerIdentifier)
                    checkConsent = true;
                }
            break;
            case 'consent':
                if (checkConsent) {
                    if (!answers['consent']['acceptConsent']) {
                        setError(errors, 'consent', true, 'you have to accept the terms of use')
                        validElement = false;
                    } else {
                        setError(errors, 'consent', false, '')
                    }
                }
            break;
            case 'companyDetails':
                if (answers['companyDetails']) {
                    Object.keys(answers['companyDetails']).forEach(key => {
                        const data = answers['companyDetails'][key]

                        if (key !== 'type' 
                            && typeof data === 'string' 
                            && data.trim()) {
                            required = true
                        }
                    })
                }

                if (required) {
                    validElement = validateCompanyDetails(element, answers, errors, 'companyDetails')
                }
            break;
            case 'billingDetails':
                let checkKeys = ['type'];

                if (answers['billingDetails']) {

                    switch (answers['billingDetails']['method']) {
                        case 'paper':
                            checkKeys = checkKeys.concat(['email','e_address','operator']);
                        break;
                        case 'email':
                            checkKeys = checkKeys.concat(['streetAddress','postalCode','city','e_address','operator']);
                        break;
                        case 'Einvoice':
                            checkKeys = checkKeys.concat(['streetAddress','postalCode','city','email']);
                        break;
                    }
                    Object.keys(answers['billingDetails']).forEach(key => {
                        const data = answers['billingDetails'][key]
                        const validKey = checkKeys.indexOf(key) === -1;

                        if (validKey && data && data.trim()) {
                            required = true;
                        }                            
                    })
                }

                if (required) {
                    validElement = validateBillingDetails(element, answers, errors, 'billingDetails', checkKeys)
                }   
            break;
            case 'invitation':
                if (element.required) {
                    validElement = validateContactDetails(element, answers, errors, 'invitation-' + element.surveyElementId)
                }                        
            break;
            case 'upload':
            break;
            /*
            case 'trainerRepeater':
                const trainers = settings.eventTrainers || [];
                
                trainers.forEach((trainer) => {
                    if (!validElement) return;
                    
                    validElement = validate(element.elements, answers, errors, '-tpid-'+trainer.personId);
                })
            break;
            */
            case 'conditionalArea':
                if (validateConditions(element.conditions, answers)) {
                    validElement = validate(element.elements, answers, errors)
                }
            break;
            case 'attendeeDetails':
                validElement = validate(
                    [element.quantityElement].concat(element.contactElements), 
                    answers
                )
            break;
        }
        
        if (validPage && !validElement) {
            validPage = false
        }
    })

    return validPage
}

export function validateQuestion(element, answers, errors, answerIdAddition = false) {
    let answer = structuredClone(answers['q-' + element.questionId + (answerIdAddition || '')]);
    let valid = true;
    let message = '';
    
    if (element.required === true) {
        // 1. make sure there is an answer defined
        // 2. check that value is not empty (numeric value 0 is valid)
        // 3. double check on string values for spacebars
        if ((typeof answer === 'undefined' || typeof answer.value === 'undefined')
            || answer.value === null
            || (!isFinite(answer.value) && !answer.value) 
            || (typeof answer.value === 'string' && answer.value.trim().length === 0)) {
            valid = false;
        }
    }

    if (typeof answer !== 'undefined') {
        if (element.questionType === 'textfield') {
            if (answer.type === 'numeric') {
                if (isFinite(answer.value) === false) {
                    valid = false;
                    message = 'please enter a number for your answer'
                }
            } else if (answer.type === 'date') {
                const date = new Date(answer.value)

                if (date.toString() === 'Invalid Date') {
                    message = 'please enter a valid date'
                }
            }
        }

        if (answer.extra && answer.extra.else
                && answer.extra.else.checked
                && !answer.extra.else.answer.trim()) {
            valid = false;
            message = 'fill the custom answer'
        }
        
        if (typeof element.settings !== 'undefined' 
            && typeof element.settings.customValidation === 'function') {
            let customValidation = element.settings.customValidation(element, answer);
            
            if (!customValidation.valid) {
                valid = false;
                message = customValidation.message;
            }
        }
    }

    if (!valid) {
        setError(errors, element.questionId, true, message)
    } else {
        setError(errors, element.questionId, false, '')
    }
    
    return valid;
}

export function validateContactDetails(element, answers, errors, type) {
    let answer = structuredClone(answers[type]),
        valid = true,
        message = '',
        defaultErrorMessage = 'we require you to fill all of these fields'

    if (!element.required) {
        defaultErrorMessage = 'if you wish to fill in your details fill atleast your first name, last name and email'
        answer = {
            firstName: answer.firstName,
            familyName: answer.familyName,
            email: answer.email,
            acceptConsent: answer.acceptConsent
        }
    }

    Object.keys(answer).forEach(key => {
        const value = answer[key]

        if ((value !== null && typeof value !== 'undefined') && value.length === 0) {
            valid = false;
            message = defaultErrorMessage;
        } else if (key === 'email' && !validateEmail(value)) {
            valid = false;
            message = 'please provide a legit email address youremail@example.com'
        } else if (key === 'dateOfBirth' && value === null) {
            valid = false;
            message = 'please provide a proper date of birth'
        } else if (['password', 'repeatPassword'].includes(key) && answer['password'] !== answer['repeatPassword']) {
            valid = false;
            message = 'passwords do not match!'
        }
    })

    if (!valid) {
        setError(errors, 'contactDetails', true, message)
    } else {
        setError(errors, 'contactDetails', false, '')
    }

    return valid;
}

export function validateCompanyDetails(element, answers, errors, type) {
    let answer = structuredClone(answers[type]),
        valid = true,
        message = '';

    Object.keys(answer).forEach(key => {
        const value = answer[key]

        if ((value !== null && typeof value !== 'undefined') && value.length === 0) {
            valid = false;
            message = 'we require you to fill all of these fields'
        } else if (key === 'email' && !validateEmail(value)) {
            valid = false;
            message = 'please provide a legit email address youremail@example.com'
        }
    })

    if (!valid) {
        setError(errors, 'companyDetails', true, message)
    } else {
        setError(errors, 'companyDetails', false, '')
    }

    return valid;
}

export function validateBillingDetails(element, answers, errors, type, ignore) {
    let answer = structuredClone(answers[type]),
        valid = true,
        message = '';
        
    if (answer['method']) {
        Object.keys(answer).forEach(key => {
            const value = answer[key]

            if (ignore.includes(key))
                return;

            if ((value !== null && typeof value !== 'undefined') && value.length === 0) {
                valid = false;
                message = 'we require you to fill all of these fields'
            } else if (key === 'email' && !validateEmail(value)) {
                valid = false;
                message = 'please provide a legit email address youremail@example.com'
            }
        })
    } else {
        valid = false;
        message = 'please select a billing method'
    }

    if (!valid) {
        setError(errors, 'billingDetails', true, message)
    } else {
        setError(errors, 'billingDetails', false, '')
    }

    return valid;
}