<template>
<div class="survey-conditionalarea">
    <div class="survey-element" 
        v-for="(element, index) in elements" 
        :key="element.surveyElementId"
        :class="elementClass(element)">
        <slot :item="element" :index="index"></slot>
    </div>
</div>
</template>

<script>
export default {
    props: {
        element: {
            type: Object,
            required: true,
        },
    },
    computed: {
        elements() {
            const { elements } = this.element

            if (Array.isArray(elements)) {
                return elements
            }

            return []
        },
        elementClass() {
            return (element) => {
                const classes = [
                    `element-${ element.surveyElementId }`
                ]

                if (element.questionType) {
                    classes.push(`element-question-${ element.questionType }`)

                    if (element.required && !element.settings.hideRequiredLabel) {
                        classes.push('question-required')
                    }
                }
                
                return classes
            }
        },
    },
    mounted() {
        //console.log('ConditionalArea', this.element.elements, this.element.conditions)
    }
}
</script>