<template>
<div class="survey-companydetails">
    <div class="survey-companydetails__companyName" v-if="optionEnabled('name')">
        <label for="companyName">{{ $_locale('company') }}</label>
        <input type="text" 
            v-model="name" 
            id="companyName"
            name="companyName" 
            maxlength="85"
            :disabled="isFieldDisabled('name')"
            @input="onValueChange" />
    </div>

    <div class="survey-companydetails__companyName" v-if="optionEnabled('businessId')">
        <label for="businessId">{{ $_locale('business id') }}</label>
        <input type="text" 
            v-model="businessId" 
            id="businessId"
            name="businessId" 
            maxlength="85"
            :disabled="isFieldDisabled('businessId')"
            @input="onValueChange" />
    </div>

    <hr v-if="optionEnabled('address') || optionEnabled('country') || optionEnabled('phone')" />

    <template v-if="optionEnabled('address')">
        <div class="survey-companydetails__streetAddress">
            <label for="streetAddress">{{ $_locale('address') }}</label>
            <input type="text" 
                v-model="streetAddress" 
                id="streetAddress"
                name="streetAddress" 
                maxlength="85"
                :disabled="isFieldDisabled('address')"
                @input="onValueChange" />
        </div>

        <div class="survey-companydetails__postalCode">
            <label for="postalCode">{{ $_locale('postal code') }}</label>
            <input type="text" 
                v-model="postalCode" 
                id="postalCode"
                name="postalCode" 
                maxlength="85"
                :disabled="isFieldDisabled('address')"
                @input="onValueChange" />
        </div>

        <div class="survey-companydetails__city">
            <label for="city">{{ $_locale('postal city') }}</label>
            <input type="text" 
                v-model="city" 
                id="city"
                name="city" 
                maxlength="85"
                :disabled="isFieldDisabled('address')"
                @input="onValueChange" />
        </div>
    </template>

    <div class="survey-companydetails__phone" v-if="optionEnabled('phone')">
        <label for="phone">{{ $_locale('phone') }}</label>
        <input type="text" 
            v-model="phone" 
            id="phone"
            name="phone" 
            maxlength="44"
            :disabled="isFieldDisabled('phone')"
            @input="onValueChange" />
    </div>

    <div class="survey-companydetails__country" v-if="optionEnabled('country')">
        <label for="country">{{ $_locale('country') }}</label>

        <template v-if="countries">
            <select id="country" 
                v-model="country" 
                :disabled="isFieldDisabled('country')"
                @change="onValueChange">
                <option value="">{{ $_locale('select country') }}</option>
                <option v-for="country in countries" 
                    :key="country.code" 
                    :value="country.code">
                    {{ country.name }}
                </option>
            </select>
        </template>
        <template v-else>
            <input type="text" 
                v-model="country" 
                id="country"
                name="country" 
                maxlength="45"
                :disabled="isFieldDisabled('country')"
                @input="onValueChange" />
        </template>
    </div>
</div>
</template>

<script>
export default {
    emits: ['valueChange'],
    props: {
        element: {
            type: Object,
            required: true,
        },
        initialValue: {
            required: false,
        },
    },
    data: () => ({
        countries: [],
        disabledFields: [],
        name: '',
        businessId: '',
        streetAddress: '',
        postalCode: '',
        city: '',
        phone: '',
        country: '',
    }),
    computed: {
        isFieldDisabled() {
            const disabledFields = this.disabledFields

            return (name) => disabledFields.includes(name)
        },
        optionEnabled() {
            const { options } = this.element

            return (name) => options.includes(name)
        },
    },
    methods: {
        handleEmit() {
            const { options, type } = this.element
            const value = {
                type: 'companyDetails',
            }

            options.forEach(option => {
                if (option === 'address') {
                    value['streetAddress'] = this.streetAddress
                    value['city'] = this.city
                    value['postalCode'] = this.postalCode
                } else {
                    value[option] = this[option]
                }
            })

            this.$emit('valueChange', { questionId: 'companyDetails', value, questionType: type })
        },
        // handle value and emit valueChange
        onValueChange() {
            this.$nextTick(this.handleEmit())
        },
    },
    mounted() {
        const settings = window.wnSurveySettings || {}

        if (settings?.disabledFields?.companyDetails) {
            this.disabledFields = settings.disabledFields.companyDetails
        }
        
        if (this.initialValue) {
            Object.keys(this.initialValue).forEach(key => {
                const value = this.initialValue[key]
                
                this[key] = value
                console.log('companyDetails', this[key], value)
            })
        }

        if (window.countries) {
            this.countries = Object.keys(window.countries).map((key) => {
                return {
                    code: key, 
                    name: window.countries[key]
                }
            })
        }

        this.onValueChange()
    }
}
</script>