<template>
<div class="survey-billingdetails">
    <div class="survey-billingdetails__billingMethod">
        <label for="billingMethod">{{ $_locale('billing method') }}</label>
        <select id="billingMethod"
            v-model="method">
            <option value="">{{ $_locale('select billing method') }}</option>
            <option v-for="opt in billingMethods" 
                :key="opt.value" 
                :value="opt.value">
                {{ opt.text }}
            </option>
        </select>
    </div>

    <template v-if="method === 'paper'">
        <div class="survey-billingdetails__streetAddress">
            <label for="streetAddress">{{ $_locale('address') }}</label>
            <input type="text" 
                v-model="streetAddress" 
                id="streetAddress"
                name="streetAddress" 
                maxlength="85"
                :disabled="isFieldDisabled('address')"
                @input="onValueChange" />
        </div>

        <div class="survey-billingdetails__postalCode">
            <label for="postalCode">{{ $_locale('postal code') }}</label>
            <input type="text" 
                v-model="postalCode" 
                id="postalCode"
                name="postalCode" 
                maxlength="85"
                :disabled="isFieldDisabled('address')"
                @input="onValueChange" />
        </div>

        <div class="survey-billingdetails__city">
            <label for="city">{{ $_locale('postal city') }}</label>
            <input type="text" 
                v-model="city" 
                id="city"
                name="city" 
                maxlength="85"
                :disabled="isFieldDisabled('address')"
                @input="onValueChange" />
        </div>
    </template>
    <template v-else-if="method === 'Einvoice'">
        <div class="survey-billingdetails__operator">
            <label for="operator">{{ $_locale('internet invoicing operator') }}</label>
            <select id="operator" 
                v-model="operator" 
                :disabled="isFieldDisabled('operator')"
                @change="onValueChange">
                <option value="">{{ $_locale('select') }} {{ $_locale('internet invoicing operator', false) }}</option>
                <option v-for="opt in $_billingOperators" 
                    :key="opt.value" 
                    :value="opt.value">
                    {{ opt.text }}
                </option>
            </select>
        </div>

        <div class="survey-billingdetails__eaddress">
            <label for="e_address">{{ $_locale('internet invoicing address') }}</label>
            <input type="text" 
                v-model="e_address" 
                id="e_address"
                name="e_address" 
                maxlength="85"
                :disabled="isFieldDisabled('e_address')"
                @input="onValueChange" />
        </div>
    </template>
    <template v-else-if="method === 'email'">
        <div class="survey-billingdetails__email">
            <label for="email">{{ $_locale('email') }}</label>
            <input type="email" 
                v-model="email" 
                id="email"
                name="email" 
                maxlength="255"
                :disabled="isFieldDisabled('email')"
                @input="onValueChange" />
        </div>
    </template>
</div>
</template>

<script>
export default {
    emits: ['valueChange'],
    props: {
        element: {
            type: Object,
            required: true,
        },
        initialValue: {
            required: false,
        },
    },
    data: () => ({
        billingMethods: [],
        disabledFields: [],
        method: '',
        streetAddress: '',
        postalCode: '',
        city: '',
        operator: '',
        e_address: '',
        email: '',
    }),
    computed: {
        isFieldDisabled() {
            const disabledFields = this.disabledFields

            return (name) => disabledFields.includes(name)
        },
        optionEnabled() {
            const { options } = this.element

            return (name) => options.includes(name)
        },
    },
    methods: {
        handleEmit() {
            const { type } = this.element
            const { 
                method, 
                streetAddress, 
                postalCode, 
                city, 
                operator, 
                e_address, 
                email 
            } = this
            const value = {
                type: 'billingDetails',
                method, 
                streetAddress, 
                postalCode, 
                city, 
                operator, 
                e_address, 
                email
            }

            this.$emit('valueChange', { questionId: 'billingDetails', value, questionType: type })
        },
        // handle value and emit valueChange
        onValueChange() {
            this.$nextTick(this.handleEmit())
        },
    },
    mounted() {
        const settings = window.wnSurveySettings || {}

        if (settings?.disabledFields?.billingDetails) {
            this.disabledFields = settings.disabledFields.billingDetails
        }
        
        if (this.initialValue) {
            Object.keys(this.initialValue).forEach(key => {
                const value = this.initialValue[key]
                
                this[key] = value
                console.log('billingDetails', this[key], value)
            })
        }

        this.billingMethods = settings.billingMethods || [ 
            { text: this.$_locale('paperinv'), value: 'paper' },
            { text: this.$_locale('email'), value: 'email' },
            { text: this.$_locale('Einvoice'), value: 'Einvoice' }
        ]

        this.onValueChange()
    }
}
</script>